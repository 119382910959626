import { Flex } from '@ebay-certilogo/design-system-web';
import { padding } from '@ebay-certilogo/design-system-web/dist/shared/styles';
import styled from 'styled-components';

const alignMap = {
  left: 'start',
  center: 'center',
  right: 'end',
  justify: 'center',
};

const alignVerticalMap = {
  top: 'flex-start',
  center: 'center',
  bottom: 'flex-end',
};

export const Wrapper = styled(Flex)`
  position: relative;
  cursor: pointer;
`;

export const Overlay = styled(Flex)`
  position: absolute;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: ${({ alignVertical }) => alignVerticalMap[alignVertical]};
  align-items: ${({ align }) => alignMap[align]};
  ${padding('s')}
`;

export default {
  Overlay,
};
